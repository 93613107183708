import Parse from 'parse';
import history from 'custom-history';
import * as Sentry from '@sentry/browser';
import * as Config from '../config.json';
import {
  Employer,
  Employee,
  PublicEmployee,
  PostReviewParams,
  SubEmployee,
  Branch,
  EmployeeInviteParams,
  ChargeType,
  RatingType,
  User,
  ScheduledReportInput,
  GetReportInput,
  EmployerDropdownType,
  EmployerDropdownDto,
} from './types';

export class ApiService {
  static async init() {
    Parse.initialize(Config.parse.appId, Config.parse.javascriptKey);
    Parse.serverURL = Config.parse.serverURL;
  }

  static handleParseError(error: Parse.Error) {
    // console.log(error);
    const pathname = history.location.pathname;

    switch (error.code) {
      case Parse.Error.INVALID_SESSION_TOKEN:
        if (
          pathname.includes('tip') ||
          pathname.includes('feedback') ||
          pathname.includes('confirmation')
        ) {
          Parse.User.logOut();
          window.location.reload();
        }
        history.replace('/login');
        break;
      default:
        alert('Error ' + error.message);
        Sentry.captureException(error);
        break;
    }

    throw error;
  }

  static async signup(
    email: string,
    firstname: string,
    lastname: string,
    password: string,
    orgName: string,
    orgType: string,
    industry: string,
    jobTitle: string,
    phone: string,
    description: string,
    website: string
  ) {
    const user = new Parse.User();
    user.set('firstname', firstname);
    user.set('lastname', lastname);
    user.set('username', email);
    user.set('password', password);
    user.set('email', email);
    user.set('jobTitle', jobTitle);
    user.set('phone', phone);

    try {
      await user.signUp();
      ApiService.createEmployerForNewUser(
        user,
        orgName,
        orgType,
        industry,
        description,
        website
      );
      // Hooray! Let them use the app now.
    } catch (error) {
      // Show the error message somewhere and let the user try again.
      return error;
    }
  }

  static async login(username: string, password: string) {
    return Parse.User.logIn(username.toLowerCase(), password);
    // const signInStatus = firebase.auth().isSignInWithEmailLink(window.location.href)
    // await Parse.User.logIn(username.toLowerCase(), password).then((result) => {
    //   if(!result.attributes.emailVerification && !result.attributes.phoneVerification) {
    //     result.set('authorized', true)
    //     result.save();
    //   }

    //   if(result.attributes.emailVerification && signInStatus) {
    //     result.set('authorized', true)
    //     result.save();
    //   }
    //   return result;
    // });
  }

  static async isUserLoggedIn(): Promise<boolean> {
    // const currentUser = Parse.User.current();
    // if (!currentUser || !currentUser.attributes.authorized) {
    //   return false;
    // }

    if (!Parse.User.current()) {
      return false;
    }

    const roleQuery = new Parse.Query(Parse.Role);
    roleQuery.equalTo('users', Parse.User.current());
    try {
      await roleQuery.find();
    } catch (error) {
      return false;
    }

    return true;
  }

  static async logout() {
    // const currentUser = Parse.User.current();
    // currentUser?.set('authorized', false);
    // await currentUser?.save();
    // window.localStorage.removeItem('emailForSignIn');
    if (Parse.User.current()) {
      Parse.User.logOut();
    }
  }

  static async resetPassword(email: string) {
    try {
      await Parse.User.requestPasswordReset(email);
    } catch (error) {
      ApiService.handleParseError(error);
    }
  }

  static getLoggedInUser() {
    if (!Parse.User.current()) {
      return null;
    }

    return Parse.User.current();
  }

  static async linkAccountWithStripe(code: string) {
    const currentUser = Parse.User.current();
    if (currentUser) {
      try {
        await Parse.Cloud.run('linkStripeAccount', { code });
        await currentUser.fetch();
      } catch (error) {
        alert('Something went wrong: ' + error.message);
      }
    }
  }

  static async getRequestUserInfo(email: string) {
    return await Parse.Cloud.run('getUserInfoFromEmail', { email });
  }

  static async getStripeDashboardUrl(employerId?: string): Promise<{ [key: string]: string }> {
    return await Parse.Cloud.run('getStripeDashboardUrl', {
      employerId
    });
  }

  static async getBranchInfo() {
    const user = Parse.User.current();
    const Branches = Parse.Object.extend('Branches');
    const branch = new Parse.Query(Branches);
    if (user) {
      const branchInfo = await branch
        .equalTo('employer', user.get('employer'))
        .equalTo('user', user)
        .first();
      return {
        name: branchInfo ? branchInfo.get('name') : null,
      };
    }

    return false;
  }

  static async saveUser(user: Parse.User, fields: { [key: string]: string }) {
    ApiService.setIfChanged(user, fields, 'email');
    ApiService.setIfChanged(user, fields, 'username');
    ApiService.setIfChanged(user, fields, 'firstname');
    ApiService.setIfChanged(user, fields, 'lastname');
    ApiService.setIfChanged(user, fields, 'jobTitle');
    ApiService.setIfChanged(user, fields, 'phone');
    ApiService.setIfChanged(user, fields, 'phoneVerification');
    ApiService.setIfChanged(user, fields, 'emailVerification');

    try {
      await user.save();
    } catch (error) {
      ApiService.handleParseError(error);
    }
  }

  static async checkForUserEmailDuplicateEntry( email: string ) {
    const hasExistingUser = await Parse.Cloud.run('hasDuplicateUsers', { email });

    return hasExistingUser;
  }

  static async checkForUserEmployerDuplicate( orgName: string ) {
    const hasExistingCompanyName = await Parse.Cloud.run('hasDuplicateEmployers', { name: orgName });

    return hasExistingCompanyName;
  }

  static async getUserForEmployer(employer: Employer) {
    const Employer = Parse.Object.extend('Employer');
    const tempEmployer = new Employer();
    tempEmployer.id = employer.id;

    const User = Parse.Object.extend('User');
    const userQuery = new Parse.Query(User);
    const user = await userQuery
      .equalTo('employer', tempEmployer)
      .notEqualTo('level', 1)
      .first();

    return user;
  }

  //////
  /////
  ////  Employer Stuff
  ///
  static getEmployerFromParseObjects(
    parseEmployer: Parse.Object,
    parseEmployerPrivate?: Parse.Object
  ): Employer {
    const employer: Employer = {
      name: parseEmployer.get('name'),
      email: parseEmployer.get('email'),
      employerType: parseEmployer.get('employerType'),
      industry: parseEmployer.get('industry'),
      description: parseEmployer.get('description'),
      link: parseEmployer.get('link'),
      logoUrl: parseEmployer.get('logoUrl'),
      stripeDeduct: parseEmployer.get('stripeDeduct'),
      disableWriteOnQrCode: parseEmployer.get('disableWriteOnQrCode'),
      disableViewTransactions: parseEmployer.get('disableViewTransactions'),
      disableFeedbackTable: parseEmployer.get('disableFeedbackTable'),
      disableOrgSetting: parseEmployer.get('disableOrgSetting'),
      enableWhiteLabel: parseEmployer.get('enableWhiteLabel'),
      enableCustomConfirmation: parseEmployer.get('enableCustomConfirmation'),
      customConfirmationUrl: parseEmployer.get('customConfirmationUrl'),
      customConfirmationUrlText: parseEmployer.get('customConfirmationUrlText'),
      isFeeMandatory: parseEmployer.get('isFeeMandatory'),
      enableAutomatedReports: parseEmployer.get('enableAutomatedReports'),
      enableCustomFeedbackField: parseEmployer.get('enableCustomFeedbackField'),
      enableMasterQRCodes: parseEmployer.get('enableMasterQRCodes'),
      masterQRCodeEmployeeSelectionLabel: parseEmployer.get('masterQRCodeEmployeeSelectionLabel'),
      masterQRCodeEmployeeDropdownTitle: parseEmployer.get('masterQRCodeEmployeeDropdownTitle'),
      masterQRCodeEmployeeSearchPlaceholder: parseEmployer.get('masterQRCodeEmployeeSearchPlaceholder'),
      enableCustomBranding: parseEmployer.get('enableCustomBranding'),
      customBrandingStyles: parseEmployer.get('customBrandingStyles') ? JSON.parse(parseEmployer.get('customBrandingStyles')) : {},
      enableFeedbackFields: parseEmployer.get('enableFeedbackFields'),
      enableFeedbackFieldLocation: parseEmployer.get('enableFeedbackFieldLocation'),
      enableFeedbackFieldDepartment: parseEmployer.get('enableFeedbackFieldDepartment'),
      enableFeedbackFieldRoomNumber: parseEmployer.get('enableFeedbackFieldRoomNumber'),
      enableFeedbackFieldPropertyName: parseEmployer.get('enableFeedbackFieldPropertyName'),
      customFeedbackFieldTitle: parseEmployer.get('customFeedbackFieldTitle'),
      customFeedbackFieldLabel: parseEmployer.get('customFeedbackFieldLabel'),
      tipAmount: parseEmployer.get('tipAmount'),
      parseObj: parseEmployer,
      id: parseEmployer.id,
    };

    if (parseEmployerPrivate) {
      employer.tier = parseEmployerPrivate.get('tier');
      employer.stripeId = parseEmployerPrivate.get('stripeId');
      employer.employerPrivateId = parseEmployerPrivate.id;
      employer.employerPrivateParseObj = parseEmployerPrivate;
    }

    return employer;
  }

  static async getEmployer(): Promise<Employer | undefined> {
    const currentUser = Parse.User.current();
    if (currentUser && currentUser.get('employer')) {
      try {
        const parseEmployer = await currentUser.get('employer').fetch();
        let parseEmployerPrivate;
        if (parseEmployer && currentUser.get('employerprivate')) {
          if (currentUser.get('sub_company')) {
            // so that this branch will use main companies stripe account
            parseEmployerPrivate = await Parse.Cloud.run(
              'subCompanyEmployerPrivate',
              { employerPrivateId: currentUser.get('employerprivate').id }
            );
          } else if (currentUser.get('level') !== 1) {
            parseEmployerPrivate = await currentUser
              .get('employerprivate')
              .fetch();
          }
        }
        return this.getEmployerFromParseObjects(
          parseEmployer,
          parseEmployerPrivate
        );
      } catch (error) {
        // console.error(error);
        ApiService.handleParseError(error);
        throw error;
      }
    }

    return undefined;
  }

  static async getEmployerById(
    employerId: string,
    employerPrivateId?: string | null
  ): Promise<Employer> {
    const Employer = Parse.Object.extend('Employer');
    const employerQuery = new Parse.Query(Employer);
    const employerPromise = employerQuery.get(employerId);
    const EmployerPrivate = Parse.Object.extend('EmployerPrivate');
    const employerPrivateQuery = new Parse.Query(EmployerPrivate);

    let employerPrivate: Parse.Object | undefined = undefined;
    if (employerPrivateId) {
      const employerPrivatePromise =
        employerPrivateQuery.get(employerPrivateId);
      employerPrivate = await employerPrivatePromise;
    }

    const employer = await employerPromise;

    return this.getEmployerFromParseObjects(employer, employerPrivate);
  }

  static async saveEmployer(employer: Employer, fields: any, logo: any) {
    this.setIfChanged(employer.parseObj, fields, 'name');
    this.setIfChanged(employer.parseObj, fields, 'email');
    this.setIfChanged(employer.parseObj, fields, 'employerType');
    this.setIfChanged(employer.parseObj, fields, 'industry');
    this.setIfChanged(employer.parseObj, fields, 'link');
    this.setIfChanged(employer.parseObj, fields, 'tipAmount');
    this.setIfChanged(employer.parseObj, fields, 'description');
    this.setIfChanged(employer.parseObj, fields, 'stripeDeduct');
    this.setIfChanged(employer.parseObj, fields, 'disableWriteOnQrCode');
    this.setIfChanged(employer.parseObj, fields, 'disableViewTransactions');
    this.setIfChanged(employer.parseObj, fields, 'disableFeedbackTable');
    this.setIfChanged(employer.parseObj, fields, 'disableOrgSetting');
    this.setIfChanged(employer.parseObj, fields, 'enableWhiteLabel');
    this.setIfChanged(employer.parseObj, fields, 'enableCustomConfirmation');
    this.setIfChanged(employer.parseObj, fields, 'customConfirmationUrl');
    this.setIfChanged(employer.parseObj, fields, 'customConfirmationUrlText');
    this.setIfChanged(employer.parseObj, fields, 'isFeeMandatory');
    this.setIfChanged(employer.parseObj, fields, 'enableAutomatedReports');
    this.setIfChanged(employer.parseObj, fields, 'enableCustomFeedbackField');
    // this.setIfChanged(employer.parseObj, fields, 'enableFeedbackFields');
    this.setIfChanged(employer.parseObj, fields, 'enableMasterQRCodes');
    this.setIfChanged(employer.parseObj, fields, 'masterQRCodeEmployeeSelectionLabel');
    this.setIfChanged(employer.parseObj, fields, 'masterQRCodeEmployeeDropdownTitle');
    this.setIfChanged(employer.parseObj, fields, 'masterQRCodeEmployeeSearchPlaceholder');

    this.setIfChanged(employer.parseObj, fields, 'enableCustomBranding');
    this.setIfChanged(employer.parseObj, fields, 'customBrandingStyles');

    this.setIfChanged(employer.parseObj, fields, 'enableFeedbackFieldLocation');
    this.setIfChanged(employer.parseObj, fields, 'enableFeedbackFieldDepartment');
    this.setIfChanged(employer.parseObj, fields, 'enableFeedbackFieldRoomNumber');
    this.setIfChanged(employer.parseObj, fields, 'enableFeedbackFieldPropertyName');
    this.setIfChanged(employer.parseObj, fields, 'customFeedbackFieldTitle');
    this.setIfChanged(employer.parseObj, fields, 'customFeedbackFieldLabel');

    // set image if exists and doesn't match
    if (logo && logo.url() !== employer.parseObj.get('logoUrl')) {
      employer.parseObj.set('logo', logo);
      employer.parseObj.set('logoUrl', logo.url());
    }

    try {
      await employer.parseObj.save();
      if (fields.tier && employer.employerPrivateParseObj) {
        this.setIfChanged(employer.employerPrivateParseObj, fields, 'tier');
        await employer.employerPrivateParseObj.save();
      }
    } catch (error) {
      ApiService.handleParseError(error);
    }
  }

  static async createEmployerForNewUser(
    user: Parse.User,
    name: string,
    type: string,
    industry: string,
    description: string,
    website: string
  ) {
    // create public employer
    const Employer = Parse.Object.extend('Employer');
    const employer = new Employer();
    employer.set('employerType', type);
    employer.set('name', name);
    employer.set('industry', industry);
    employer.set('description', description);
    employer.set('link', website);
    employer.set('tipAmount', '0');
    const savedEmployer = await employer.save();
    ApiService.createEmployerRole(user, savedEmployer);

    // create private employer
    const EmployerPrivate = Parse.Object.extend('EmployerPrivate');
    const employerPrivate = new EmployerPrivate();
    employerPrivate.set('employer', savedEmployer);
    employerPrivate.set('tier', 'free');
    const savedEmployerPrivate = await employerPrivate.save();
    ApiService.createPrivateEmployerRole(user, savedEmployerPrivate);

    // update employer and ACL for user
    user.set('employer', savedEmployer);
    user.set('employerprivate', savedEmployerPrivate);
    const userAcl = new Parse.ACL();
    userAcl.setRoleReadAccess('super-admin', true);
    userAcl.setRoleWriteAccess('super-admin', true);
    userAcl.setPublicReadAccess(false);
    user.setACL(userAcl);
    await user.save();
  }

  static async createEmployerRole(user: Parse.User, employer: Parse.Object) {
    const roleACL = new Parse.ACL();
    roleACL.setPublicReadAccess(true);
    const role = new Parse.Role(employer.id + '-admin', roleACL);
    role.getUsers().add(user);
    const savedRole = await role.save();
    const employerAcl = new Parse.ACL();
    employerAcl.setPublicWriteAccess(false);
    employerAcl.setPublicReadAccess(true);
    employerAcl.setRoleReadAccess('super-admin', true);
    employerAcl.setRoleWriteAccess('super-admin', true);
    employerAcl.setRoleWriteAccess(savedRole, true);
    employer.setACL(employerAcl);
    await employer.save();
  }

  static async createPrivateEmployerRole(
    user: Parse.User,
    employerPrivate: Parse.Object
  ) {
    const roleACL = new Parse.ACL();
    roleACL.setPublicReadAccess(true);
    const role = new Parse.Role(employerPrivate.id + '-admin', roleACL);
    role.getUsers().add(user);
    const savedRole = await role.save();
    const employerAcl = new Parse.ACL();
    employerAcl.setPublicWriteAccess(false);
    employerAcl.setPublicReadAccess(false);
    employerAcl.setRoleReadAccess('super-admin', true);
    employerAcl.setRoleWriteAccess('super-admin', true);
    employerAcl.setRoleWriteAccess(savedRole, true);
    employerAcl.setRoleReadAccess(savedRole, true);
    employerPrivate.setACL(employerAcl);
    await employerPrivate.save();
  }

  // TODO: Rename to getDefaultCurrency
  // Technically an employee can have a different currency than their employer
  // Not sure if we want to enforce employees to all have a common currency
  static async getEmployerDefaultCurrency(
    employerStripeId?: string,
    workerId?: string
  ) {
    return Parse.Cloud.run('getEmployerDefaultCurrency', {
      employerStripeId,
      workerId,
    });
  }

  //////
  /////
  ////  Employee Stuff
  ///

  static async getEmployee(id: string): Promise<PublicEmployee | undefined> {
    const Worker = Parse.Object.extend('Worker');
    const workerQuery = new Parse.Query(Worker);

    workerQuery.include(['employer.id,employer.name']);
    let worker: PublicEmployee | undefined;
    try {
      const w = await workerQuery.get(id);
      worker = {
        id: w.id,
        displayname: w.get('displayname'),
        jobTitle: w.get('jobTitle'),
        thirdLine: w.get('thirdLine'),
        locationParseObject: w.get('locationId'),
        locationId: w.get('locationId')?.id,
        propertyParseObject: w.get('propertyId'),
        propertyId: w.get('propertyId')?.id,
        departmentParseObj: w.get('departmentId'),
        departmentId: w.get('departmentId')?.id,
        isMaster: !!w.get('isMaster'),
        imageUrl: w.get('imageUrl'),
        imageRatingFullUrl: w.get('imageRatingFullUrl'),
        imageRatingEmptyUrl: w.get('imageRatingEmptyUrl'),
        feedbackQuestion: w.get('feedbackQuestion'),
        type: w.attributes.employer.attributes.employerType,
        employerName: w.attributes.employer.attributes.name,
        employerId: w.attributes.employer.id,
        employerEmail: w.attributes.employer.attributes.email,
        employerParseObj: w.get('employer'),
        employerLink: w.attributes.employer.attributes.link,
        employerEnableMasterQRCodes: w.attributes.employer.attributes.enableMasterQRCodes,
        masterQRCodeEmployeeSelectionLabel: w.attributes.employer.attributes.masterQRCodeEmployeeSelectionLabel,
        masterQRCodeEmployeeDropdownTitle: w.attributes.employer.attributes.masterQRCodeEmployeeDropdownTitle,
        masterQRCodeEmployeeSearchPlaceholder: w.attributes.employer.attributes.masterQRCodeEmployeeSearchPlaceholder,
        employerEnableCustomBranding: w.attributes.employer.attributes.enableCustomBranding,
        employerCustomBrandingStyles: w.attributes.employer.attributes.customBrandingStyles ? JSON.parse(w.attributes.employer.attributes.customBrandingStyles) : {},
        employerEnableFeedbackFieldDepartment: w.attributes.employer.attributes.enableFeedbackFieldDepartment,
        employerEnableFeedbackFieldLocation: w.attributes.employer.attributes.enableFeedbackFieldLocation,
        employerEnabledFeedbackFieldPropertyName: w.attributes.employer.attributes.enableFeedbackFieldPropertyName,
        employerEnableFeedbackFieldRoomNumber: w.attributes.employer.attributes.enableFeedbackFieldRoomNumber,
        link: w.get('link'),
        description: w.get('description'),
        parseObject: w,
        hideDeductModal: w.attributes.employer.attributes.stripeDeduct,
        qrCodeUrl: `${window.location.origin}/tip?id=` + w.id,
      };
    } catch (error) {
      ApiService.handleParseError(error);
    }
    return worker;
  }

  static async getEmployeePrivate(employeeId?: string) {
    const Worker = Parse.Object.extend('Worker');
    const tempWorker = new Worker();
    tempWorker.id = employeeId;

    const WorkerPrivate = Parse.Object.extend('WorkerPrivate');
    const workerPrivateQuery = new Parse.Query(WorkerPrivate);
    let privateWorker;

    try {
      const foundWorkerPrivate = await workerPrivateQuery.equalTo('worker', tempWorker).find();

      if (foundWorkerPrivate.length > 1) {
        throw new Error('Found multiple Worker Private info for single Worker!');
      }

      const pw = foundWorkerPrivate[0];

      privateWorker = {
        id: pw.id,
        firstname: pw.get('firstname'),
        lastname: pw.get('lastname')
      };
    } catch (error) {
      ApiService.handleParseError(error);
    }
    return privateWorker;
  }

  static async getEmployerDropdowns(employerId: string): Promise<EmployerDropdownDto[]> {
    const Employer = Parse.Object.extend('Employer');
    const tempEmployer = new Employer();
    tempEmployer.id = employerId;

    const locationQuery = new Parse.Query('Location');
    const propertyQuery = new Parse.Query('Property');
    const departmentQuery = new Parse.Query('Department');

    locationQuery.equalTo('employer', tempEmployer);
    propertyQuery.equalTo('employer', tempEmployer);
    departmentQuery.equalTo('employer', tempEmployer);

    let results: EmployerDropdownDto[] = [];

    try {
      const [locations, properties, departments] = await Promise.all([
        locationQuery.find(),
        propertyQuery.find(),
        departmentQuery.find()
      ]);

      results = [
        ...locations.map(location => ({ id: location.id, name: location.get('name'), type: EmployerDropdownType.Location })),
        ...properties.map(property => ({ id: property.id, name: property.get('name'), type: EmployerDropdownType.Property })),
        ...departments.map(department => ({ id: department.id, name: department.get('name'), type: EmployerDropdownType.Department })),
      ];

    } catch (error) {
      ApiService.handleParseError(error);
    }

    return results;
  }

  static async getPublicEmployees(employer: Employer, sortKey: string, departmentId?: string) {
    if (employer) {
      const Employer = Parse.Object.extend('Employer');
      const tempEmployer = new Employer();
      tempEmployer.id = employer.id;
      const Worker = Parse.Object.extend('Worker');
      const workersQuery = new Parse.Query(Worker);

      if (departmentId) {
        const Department = Parse.Object.extend('Department');
        const tempDepartment = new Department();
        tempDepartment.id = departmentId;
        workersQuery.equalTo('departmentId', tempDepartment);
      }

      const workersQueryPromise = workersQuery
        .include('departmentId')
        .notEqualTo('deleted', true)
        .equalTo('employer', tempEmployer)
        .ascending(sortKey || 'displayname')
        .limit(500)
        .find();

      const workers = await workersQueryPromise;
      const modifiedWorkers: Array<Partial<Employee>> = [];

      for (let ndx = 0; ndx < workers.length; ++ndx) {

        const modifiedWorker: Partial<Employee> = {
          id: workers[ndx].id,
          displayname: workers[ndx].get('displayname'),
          jobTitle: workers[ndx].get('jobTitle'),
          thirdLine: workers[ndx].get('thirdLine'),
          locationId: workers[ndx].get('locationId')?.id,
          propertyId: workers[ndx].get('propertyId')?.id,
          departmentId: workers[ndx].get('departmentId')?.id,
          departmentIconType: workers[ndx].get('departmentId')?.get('iconType'),
          isMaster: !!workers[ndx].get('isMaster'),
          description: workers[ndx].get('description'),
          link: workers[ndx].get('link'),
          imageUrl: workers[ndx].get('imageUrl'),
          imageRatingFullUrl: workers[ndx].get('imageRatingFullUrl'),
          imageRatingEmptyUrl: workers[ndx].get('imageRatingEmptyUrl'),
          feedbackQuestion: workers[ndx].get('feedbackQuestion'),
          parseObject: workers[ndx],
        };

        modifiedWorkers.push(modifiedWorker);
      }

      return modifiedWorkers;
    }
  }

  static async getEmployees(employer: Employer, sortKey: string, departmentId?: string) {
    if (employer) {
      const Employer = Parse.Object.extend('Employer');
      const tempEmployer = new Employer();
      tempEmployer.id = employer.id;
      const Worker = Parse.Object.extend('Worker');
      const workersQuery = new Parse.Query(Worker);

      if (departmentId) {
        const Department = Parse.Object.extend('Department');
        const tempDepartment = new Department();
        tempDepartment.id = departmentId;
        workersQuery.equalTo('departmentId', tempDepartment);
      }

      const workersQueryPromise = workersQuery
        .include('departmentId')
        .notEqualTo('deleted', true)
        .equalTo('employer', tempEmployer)
        .ascending(sortKey || 'displayname')
        .limit(500)
        .find();

      const WorkerPrivate = Parse.Object.extend('WorkerPrivate');
      const workersPrivateDataQuery = new Parse.Query(WorkerPrivate);
      const workersPrivateDataQueryPromise = workersPrivateDataQuery
        .equalTo('employer', tempEmployer)
        .notEqualTo('deleted', true)
        .ascending(sortKey || 'displayname')
        // .include('user')
        .limit(500)
        .find();

      const workers = await workersQueryPromise;
      const workersPrivate = await workersPrivateDataQueryPromise;
      const modifiedWorkers: Array<Employee> = [];

      for (let ndx = 0; ndx < workers.length; ++ndx) {
        const privateWorker = workersPrivate.find(
          (workerPrivate) => workerPrivate.get('worker').id === workers[ndx].id
        );
        if (!privateWorker) {
          throw Error(`Private fields for worker ${workers[ndx].id} not found`);
        }

        const modifiedWorker: Employee = {
          qrCodeUrl:
            `${window.location.origin}/tip?id=` +
            privateWorker?.get('internalId'),
          id: workers[ndx].id,
          displayname: workers[ndx].get('displayname'),
          jobTitle: workers[ndx].get('jobTitle'),
          thirdLine: workers[ndx].get('thirdLine'),
          locationId: workers[ndx].get('locationId')?.id,
          propertyId: workers[ndx].get('propertyId')?.id,
          departmentId: workers[ndx].get('departmentId')?.id,
          departmentIconType: workers[ndx].get('departmentId')?.get('iconType'),
          isMaster: !!workers[ndx].get('isMaster'),
          description: workers[ndx].get('description'),
          link: workers[ndx].get('link'),
          imageUrl: workers[ndx].get('imageUrl'),
          imageRatingFullUrl: workers[ndx].get('imageRatingFullUrl'),
          imageRatingEmptyUrl: workers[ndx].get('imageRatingEmptyUrl'),
          feedbackQuestion: workers[ndx].get('feedbackQuestion'),
          directPayment: !!privateWorker?.get('user'),
          // directUsername: privateWorker.get('user')?.get('username'),
          parseObject: workers[ndx],
          parsePrivateObject: privateWorker,
          firstname: privateWorker?.get('firstname'),
          lastname: privateWorker?.get('lastname'),
          email: privateWorker?.get('email'),
          phone: privateWorker?.get('phone'),
          department: privateWorker?.get('department'),
          employeeId: privateWorker?.get('employeeId'),
          dateOfEmployment: privateWorker?.get('dateOfEmployment')
        };

        modifiedWorkers.push(modifiedWorker);
      }

      return modifiedWorkers;
    }
  }

  static async getWorkersEmployeeIdMap(params: {
    employerId: string;
  }): Promise<{ [key: string]: string } | undefined> {
    try {
      return await Parse.Cloud.run('getWorkersEmployeeIdMap', params);
    } catch (error) {
      ApiService.handleParseError(error);
    }
  }

  static async getSubCompanyEmployee(employer: Employer) {
    if (employer) {
      const user = Parse.User.current();
      const Employer = Parse.Object.extend('Employer');
      const tempEmployer = new Employer();
      tempEmployer.id = employer.id;

      const Worker = Parse.Object.extend('Worker');
      const workersQuery = new Parse.Query(Worker);
      const workers = await workersQuery
        .equalTo('employer', tempEmployer)
        .equalTo('user', user)
        .find();

      const workersPrivate = await Parse.Cloud.run(
        'getSubCompanyWorkerPrivate',
        { userId: user ? user.id : null, employerId: employer.id }
      );
      const modifiedWorkersArr: Array<SubEmployee> = [];

      for (let ndx = 0; ndx < workers.length; ++ndx) {
        const privateWorker = workersPrivate.find(
          (workerPrivate: any) =>
            workerPrivate.get('worker').id === workers[ndx].id
        );
        if (!privateWorker) {
          throw Error(`Private fields for worker ${workers[ndx].id} not found`);
        }

        const modifiedWorker: Employee = {
          qrCodeUrl:
            `${window.location.origin}/tip?id=` +
            privateWorker.get('internalId'),
          id: workers[ndx].id,
          displayname: workers[ndx].get('displayname'),
          jobTitle: workers[ndx].get('jobTitle'),
          thirdLine: workers[ndx].get('thirdLine'),
          description: workers[ndx].get('description'),
          link: workers[ndx].get('link'),
          imageUrl: workers[ndx].get('imageUrl'),
          imageRatingFullUrl: workers[ndx].get('imageRatingFullUrl'),
          imageRatingEmptyUrl: workers[ndx].get('imageRatingEmptyUrl'),
          feedbackQuestion: workers[ndx].get('feedbackQuestion'),
          parseObject: workers[ndx],
          parsePrivateObject: privateWorker,
          firstname: privateWorker.get('firstname'),
          lastname: privateWorker.get('lastname'),
          email: privateWorker.get('email'),
          phone: privateWorker.get('phone'),
          department: privateWorker.get('department'),
          employeeId: privateWorker.get('employeeId'),
          dateOfEmployment: privateWorker.get('dateOfEmployment'),
        };
        modifiedWorkersArr.push(modifiedWorker);
      }

      return modifiedWorkersArr;
    }
  }

  static async getUserPrivateEmployee() {
    const user = Parse.User.current();

    if (user) {
      const WorkerPrivate = Parse.Object.extend('WorkerPrivate');
      const workerPrivateQuery = new Parse.Query(WorkerPrivate);
      workerPrivateQuery.equalTo('user', user);
      const workerPrivate = await workerPrivateQuery.first();

      if (!workerPrivate) {
        return;
      }

      return {
        id: workerPrivate.id,
        workerId: workerPrivate.get('internalId'),
        parsePrivateObject: workerPrivate,
        firstname: workerPrivate.get('firstname'),
        lastname: workerPrivate.get('lastname'),
        qrCodeUrl:
          `${window.location.origin}/tip?id=` + workerPrivate.get('internalId'),
        qrCodeEmail: workerPrivate.get('email'),
        qrCodePhone: workerPrivate.get('phone'),
      };
    }
  }

  static async resetEmployeeRatingIcons(employee: Employee) {
    if (!employee) {
      return;
    }
    const parseWorkerObj = employee.parseObject;

    // reset rating images
    parseWorkerObj.set('imageRatingFullFile', null);
    parseWorkerObj.set('imageRatingFullUrl', null);
    parseWorkerObj.set('imageRatingEmptyFile', null);
    parseWorkerObj.set('imageRatingEmptyUrl', null);

    try {
      await parseWorkerObj.save();
    } catch (error) {
      ApiService.handleParseError(error);
    }
  }

  static async saveIndividualEmployee(
    user: {
      parseUser: Parse.User;
      fields: Partial<User & { email: string }>;
    },
    employee: {
      parseEmployee: Partial<Employee>;
      fields: Partial<Employee>;
    }
  ) {
    // User
    ApiService.setIfChanged(user.parseUser, user.fields, 'email');
    ApiService.setIfChanged(user.parseUser, user.fields, 'username');
    ApiService.setIfChanged(user.parseUser, user.fields, 'firstname');
    ApiService.setIfChanged(user.parseUser, user.fields, 'lastname');

    // Employee
    if (employee.parseEmployee.parsePrivateObject) {
      employee.parseEmployee.parsePrivateObject.set(
        'employeeId',
        employee.fields['employeeId']
      );
    }

    try {
      await user.parseUser.save();
      await employee.parseEmployee.parsePrivateObject?.save();
    } catch (error) {
      ApiService.handleParseError(error);
    }
  }

  static async saveEmployee(
    employer: Employer,
    modifiedEmployee: Employee,
    oldEmployee: Employee,
    image: any,
    imageRatingFull: any,
    imageRatingEmpty: any,
    isSubCompany: any,
    user: Parse.Object | null,
    branch: Branch
  ) {
    const Employer = Parse.Object.extend('Employer');
    const tempEmployer = new Employer();
    tempEmployer.id = employer.id;
    let parseWorkerObj = oldEmployee && oldEmployee.parseObject;
    let parseWorkerPrivateObj = oldEmployee && oldEmployee.parsePrivateObject;

    if (!!modifiedEmployee.locationId && typeof modifiedEmployee.locationId === 'string') {
      const Location = Parse.Object.extend('Location');
      const tempLocation = new Location();
      tempLocation.id = modifiedEmployee.locationId;
      modifiedEmployee.locationId = tempLocation;
    } else {
      modifiedEmployee.locationId = null;
    }

    if (!!modifiedEmployee.propertyId && typeof modifiedEmployee.propertyId === 'string') {
      const Property = Parse.Object.extend('Property');
      const tempProperty = new Property();
      tempProperty.id = modifiedEmployee.propertyId;
      modifiedEmployee.propertyId = tempProperty;
    } else {
      modifiedEmployee.propertyId = null;
    }

    if (!!modifiedEmployee.departmentId && typeof modifiedEmployee.departmentId === 'string') {
      const Department = Parse.Object.extend('Department');
      const tempDepartment = new Department();
      tempDepartment.id = modifiedEmployee.departmentId;
      modifiedEmployee.departmentId = tempDepartment;
    } else {
      modifiedEmployee.departmentId = null;
    }

    if (!parseWorkerObj) {
      const Worker = Parse.Object.extend('Worker');
      parseWorkerObj = new Worker();
      parseWorkerObj.set(
        'imageUrl',
        'https://s3-us-west-1.amazonaws.com/tipgenie-images/employees/user.png'
      );
      parseWorkerObj.set('employer', tempEmployer);
    }
    ApiService.setIfChanged(parseWorkerObj, modifiedEmployee, 'link');
    ApiService.setIfChanged(parseWorkerObj, modifiedEmployee, 'description');
    ApiService.setIfChanged(parseWorkerObj, modifiedEmployee, 'jobTitle');
    ApiService.setIfChanged(parseWorkerObj, modifiedEmployee, 'thirdLine');
    ApiService.setIfChanged(parseWorkerObj, modifiedEmployee, 'displayname');
    ApiService.setIfChanged(parseWorkerObj, modifiedEmployee, 'isMaster');
    ApiService.setIfChanged(parseWorkerObj, modifiedEmployee, 'propertyId');
    ApiService.setIfChanged(parseWorkerObj, modifiedEmployee, 'locationId');
    ApiService.setIfChanged(parseWorkerObj, modifiedEmployee, 'departmentId');
    ApiService.setIfChanged(
      parseWorkerObj,
      modifiedEmployee,
      'feedbackQuestion'
    );

    // set image if exists and doesn't match
    if (image && image.url() !== parseWorkerObj.get('imageUrl')) {
      parseWorkerObj.set('imageFile', image);
      parseWorkerObj.set('imageUrl', image.url());
    }

    if (imageRatingFull) {
      parseWorkerObj.set('imageRatingFullFile', imageRatingFull);
      parseWorkerObj.set('imageRatingFullUrl', imageRatingFull.url());
    }

    if (imageRatingEmpty) {
      parseWorkerObj.set('imageRatingEmptyFile', imageRatingEmpty);
      parseWorkerObj.set('imageRatingEmptyUrl', imageRatingEmpty.url());
    }

    if (!parseWorkerPrivateObj) {
      const WorkerPrivate = Parse.Object.extend('WorkerPrivate');
      parseWorkerPrivateObj = new WorkerPrivate();
      parseWorkerPrivateObj.set('employer', tempEmployer);
    }

    if (isSubCompany && !oldEmployee) {
      parseWorkerObj.set('user', user || Parse.User.current());
      parseWorkerObj.set('sub_company', true);
      parseWorkerPrivateObj.set('user', user || Parse.User.current());
      parseWorkerPrivateObj.set('sub_company', true);
    }

    ApiService.setIfChanged(
      parseWorkerPrivateObj,
      modifiedEmployee,
      'firstname'
    );
    ApiService.setIfChanged(
      parseWorkerPrivateObj,
      modifiedEmployee,
      'lastname'
    );
    ApiService.setIfChanged(parseWorkerPrivateObj, modifiedEmployee, 'phone');
    ApiService.setIfChanged(parseWorkerPrivateObj, modifiedEmployee, 'email');
    ApiService.setIfChanged(
      parseWorkerPrivateObj,
      modifiedEmployee,
      'department'
    );
    ApiService.setIfChanged(
      parseWorkerPrivateObj,
      modifiedEmployee,
      'employeeId'
    );
    ApiService.setIfChanged(
      parseWorkerPrivateObj,
      modifiedEmployee,
      'dateOfEmployment'
    );

    try {
      // if subCompany allow to save/update qr codes
      if (isSubCompany && oldEmployee) {
        await Parse.Cloud.run('subCompanyUpdate', {
          worker: parseWorkerObj.toJSON(),
          workerPrivate: parseWorkerPrivateObj.toJSON(),
        });
        // console.log('subcompany updated')
      } else {
        const worker = await parseWorkerObj.save();
        // console.log('worker saved')

        parseWorkerPrivateObj.set('worker', worker);
        parseWorkerPrivateObj.set('internalId', worker.id);
        await parseWorkerPrivateObj.save();
        // console.log('private worker saved')

        if (isSubCompany) {
          await Parse.Cloud.run('setWorkerBranch', {
            workerId: parseWorkerObj.id,
            workerPrivateId: parseWorkerPrivateObj.id,
            branchId: branch.id,
          });
          //console.log('branch set for worker & private worker');
        }
      }
    } catch (error) {
      ApiService.handleParseError(error);
    }
  }

  static async deleteEmployee(employee: Employee) {
    try {
      await Parse.Cloud.run('deleteEmployee', { employeeId: employee.id });
    } catch (error) {
      ApiService.handleParseError(error);
    }
  }
  /**
   * Creates a new user, worker, and workerprivate with the given params under the provided employer ID
   * @param params
   */
  static async inviteEmployee(params: EmployeeInviteParams) {
    try {
      await Parse.Cloud.run('inviteUser', {
        ...params,
        email: params.email.toLowerCase(),
      });
    } catch (error) {
      ApiService.handleParseError(error);
    }
  }

  //////
  /////
  ////  Review Stuff
  ///

  // static async getHotelReviews(begin, end) {
  //   let currentUser = Parse.User.current();
  //   if (currentUser && currentUser.get('employer')) {

  //     let ratings = [];
  //     let Rating = Parse.Object.extend('Rating');
  //     let query = new Parse.Query(Rating);
  //     query.greaterThanOrEqualTo('createdAt', begin);
  //     query.lessThanOrEqualTo('createdAt', end);
  //     query.equalTo('employer', currentUser.get('employer'));
  //     query.equalTo('ratingType', 'employer');
  //     console.log('Query', query);

  //     try {
  //       const parseRatings = await query.find();
  //       console.log('Rating', parseRatings);
  //       for (let ndx = 0; ndx < parseRatings.length; ++ndx) {
  //         ratings.push({
  //           date: parseRatings[ndx].get('createdAt'),
  //           rating: parseRatings[ndx].get('rating'),
  //           comment: parseRatings[ndx].get('comments')
  //         });
  //       }
  //       console.log('This is the rating', ratings);
  //       return ratings;

  //     } catch(error) {
  //       ApiService.handleParseError(error);
  //     }
  //   }

  //   return [];
  // }

  static async postReview(params: PostReviewParams): Promise<void> {
    const {
      comments,
      roomNumber,
      employee,
      // customField,
      employeeRating: employeeRatingNumber,
      hotelRating: hotelRatingNumber,
    } = params;
    const Rating = Parse.Object.extend('Rating');
    const employerRating = new Rating();
    const workerRating = new Rating();

    const Property = Parse.Object.extend('Property');
    const Location = Parse.Object.extend('Location');
    const Department = Parse.Object.extend('Department');

    employerRating.set('ratingType', 'employer');
    employerRating.set('employer', employee.employerParseObj);
    employerRating.set('comments', comments);
    if (roomNumber) {
      employerRating.set('roomNumber', roomNumber);
    }
    if (employee?.departmentId) {
      const tempDepartment = new Department();
      tempDepartment.id = employee?.departmentId;
      employerRating.set('department', tempDepartment);
    }
    if (employee?.locationId) {
      const tempLocation = new Location();
      tempLocation.id = employee?.locationId;
      employerRating.set('location', tempLocation);
    }
    if (employee?.propertyId) {
      const tempProperty = new Property();
      tempProperty.id = employee?.propertyId;
      employerRating.set('property', tempProperty);
    }

    if (hotelRatingNumber && hotelRatingNumber > 0) {
      employerRating.set('rating', hotelRatingNumber); // 1 through 5
    }

    workerRating.set('ratingType', 'worker');
    workerRating.set('worker', employee.parseObject);
    workerRating.set('employer', employee.employerParseObj);
    workerRating.set('comments', comments);
    // workerRating.set('customField', customField);
    if (roomNumber) {
      workerRating.set('roomNumber', roomNumber);
    }
    if (employee?.departmentId) {
      const tempDepartment = new Department();
      tempDepartment.id = employee?.departmentId;
      workerRating.set('department', tempDepartment);
    }
    if (employee?.locationId) {
      const tempLocation = new Location();
      tempLocation.id = employee?.locationId;
      workerRating.set('location', tempLocation);
    }
    if (employee?.propertyId) {
      const tempProperty = new Property();
      tempProperty.id = employee?.propertyId;
      workerRating.set('property', tempProperty);
    }

    if (employeeRatingNumber && employeeRatingNumber > 0) {
      workerRating.set('rating', employeeRatingNumber); // 1 through 5
    }

    try {
      await employerRating.save();
      await workerRating.save();
    } catch (error) {
      ApiService.handleParseError(error);
    }
  }

  static async getEmployeeReviewsV2(
    begin: Date,
    end: Date,
    isLevelOne: boolean
  ): Promise<RatingType[]> {
    const currentUser = Parse.User.current();

    if (!currentUser || !currentUser.get('employer')) {
      alert('There was a problem retrieving feedback, please try again later');
      return [];
    }

    const Rating = Parse.Object.extend('Rating');
    const ratingQuery = new Parse.Query(Rating);

    if (isLevelOne) {
      const WorkerPrivate = Parse.Object.extend('WorkerPrivate');
      const workerPrivateQuery = new Parse.Query(WorkerPrivate);
      workerPrivateQuery.equalTo('user', currentUser);
      let workerPrivate;

      try {
        workerPrivate = await workerPrivateQuery.first();
      } catch (error) {
        ApiService.handleParseError(error);
      }

      if (!workerPrivate) {
        return [];
      }

      ratingQuery.equalTo('worker', workerPrivate?.get('worker'));
      ratingQuery.include('employer');
    } else {
      ratingQuery.equalTo('employer', currentUser.get('employer'));
      ratingQuery.include('worker');
    }

    ratingQuery.equalTo('ratingType', 'worker');
    ratingQuery.descending('createdAt');
    ratingQuery.greaterThanOrEqualTo('createdAt', begin);
    ratingQuery.lessThanOrEqualTo('createdAt', end);
    ratingQuery.limit(1000);

    try {
      const parseRatings: Parse.Object<Parse.Attributes>[] =
        await ratingQuery.find();

      if (!parseRatings.length) {
        return [];
      }

      let levelOneWorker: Parse.Object<Parse.Attributes>;

      if (isLevelOne) {
        // This should be the same worker for all ratings, so only need to fetch once
        levelOneWorker = await parseRatings[0].get('worker').fetch();
      }

      return parseRatings.map((rating) => {
        return {
          name: isLevelOne
            ? levelOneWorker.get('displayname')
            : rating.get('worker').get('displayname'),
          rating: rating.get('rating'),
          comment: rating.get('comments'),
          date: rating.get('createdAt'),
        };
      });
    } catch (error) {
      ApiService.handleParseError(error);
    }

    return [];
  }

  static async createScheduledReport(input: ScheduledReportInput) {
    try {
      await Parse.Cloud.run('createScheduledReport', input);
    } catch (error) {
      ApiService.handleParseError(error);
      console.log(error);
    }
  }

  static async getReportTotalTips(input: GetReportInput) {
    try {
      return await Parse.Cloud.run('getReportTotalTips', input);
    } catch (error) {
      ApiService.handleParseError(error);
      console.log(error);
    }
  }

  static async getReportTransactions(input: GetReportInput) {
    try {
      return await Parse.Cloud.run('getReportTransactions', input);
    } catch (error) {
      ApiService.handleParseError(error);
      console.log(error);
    }
  }

  static async getReportPayout(input: GetReportInput) {
    try {
      return await Parse.Cloud.run('getReportPayout', input);
    } catch (error) {
      ApiService.handleParseError(error);
      console.log(error);
    }
  }

  static async updateScheduledReport(
    reportId: string,
    input: ScheduledReportInput
  ) {
    try {
      await Parse.Cloud.run('updateScheduledReport', {
        reportId,
        ...input,
      });
    } catch (error) {
      ApiService.handleParseError(error);
      console.log(error);
    }
  }

  static async getEmployerAdmins(employerId: string) {
    try {
      return await Parse.Cloud.run('getEmployerAdmins', {
        employerId,
      });
    } catch (error) {
      ApiService.handleParseError(error);
      console.log(error);
    }
  }

  static async deleteScheduledReport(reportId: string) {
    try {
      await Parse.Cloud.run('deleteScheduledReport', {
        reportId,
      });
    } catch (error) {
      ApiService.handleParseError(error);
      console.log(error);
    }
  }

  static async getScheduledReports(employerId: string) {
    try {
      const res = await Parse.Cloud.run('getScheduledReports', {
        employerId,
      });

      return res.map((report: any) => ({
        id: report.id,
        createdAt: report.get('createdAt'),
        // name: report.get('name'),
        interval: report.get('interval'),
        intervalFrequancy: report.get('intervalFrequancy'),
        type: report.get('type'),
        email: report.get('email'),
        scheduled: report.get('scheduled'),
        weeklyAnchor: report.get('weeklyAnchor'),
        monthlyAnchor: report.get('monthlyAnchor'),
      }));
    } catch (error) {
      ApiService.handleParseError(error);
      console.log(error);
    }
  }

  static async getScheduledReportsHistory(reportId: string) {
    try {
      const res = await Parse.Cloud.run('getScheduledReportsHistory', {
        reportId,
      });

      return res.map((report: any) => ({
        id: report.id,
        createdAt: report.get('createdAt'),
        interval: report.get('interval'),
        type: report.get('type'),
        email: report.get('email'),
        employer: report.get('employer'),
        scheduled: report.get('scheduled'),
        weeklyAnchor: report.get('weeklyAnchor'),
        monthlyAnchor: report.get('monthlyAnchor'),
        fromDate: report.get('fromDate'),
        toDate: report.get('toDate'),
      }));
    } catch (error) {
      ApiService.handleParseError(error);
      console.log(error);
    }
  }

  static async getEmployeeReviews(
    begin: Date,
    end: Date,
    isSubCompany: boolean,
    employeeIdMap: { [key: string]: string } | undefined,
    employerId: string
  ) {
    const Employer = Parse.Object.extend('Employer');
    const employerQuery = new Parse.Query(Employer);
    const employer = await employerQuery.get(employerId);

    const currentUser = Parse.User.current();
    const ratings = [];

    if (currentUser && currentUser.get('employer')) {
      const Rating = Parse.Object.extend('Rating');
      const query = new Parse.Query(Rating);
      let allRatings = [];

      if (isSubCompany) {
        allRatings = await Parse.Cloud.run('getSubCompanyRatings', {
          userId: currentUser.id,
          employerId,
          begin: begin,
          end: end,
        });
      } else {
        query.limit(1000);
        query.greaterThanOrEqualTo('createdAt', begin);
        query.lessThanOrEqualTo('createdAt', end);
        query.equalTo('employer', employer);
        query.equalTo('ratingType', 'worker');
        query.include('worker');
      }

      try {
        const parseRatings: Array<Parse.Object> = isSubCompany
          ? allRatings
          : await query.find();

        for (let ndx = 0; ndx < parseRatings.length; ++ndx) {
          const worker = parseRatings[ndx].get('worker');
          ratings.push({
            name: worker ? worker.get('displayname') : '',
            date: parseRatings[ndx].get('createdAt'),
            rating: parseRatings[ndx].get('rating'),
            comment: parseRatings[ndx].get('comments'),
            // customField: parseRatings[ndx].get('customField'),
            employeeId: employeeIdMap && employeeIdMap[worker.id],
          });
        }
      } catch (error) {
        ApiService.handleParseError(error);
      }
    }

    return ratings.reverse();
  }

  static async getPayouts(begin: any, end: any, employerId: string) {
    try {
      const payouts = await Parse.Cloud.run('getPayouts', {
        begin,
        end,
        employerId,
      });

      return payouts ? payouts : [];
    } catch (error) {
      alert('Error ' + error.message);
      Sentry.captureException(error);
    }
  }

  static async getPayoutTransactions(payoutId: string, employerId?: string) {
    try {
      const transactions = await Parse.Cloud.run('getPayoutTransactions', {
        payoutId,
        employerId: employerId
      });
      return transactions ? transactions.data.slice(1) : [];
    } catch (error) {
      alert('Error ' + error.message);
      Sentry.captureException(error);
    }
  }

  static async getEmployerTransactions(
    begin: any,
    end: any,
    isSubCompany: any,
    employeeIdMap: any,
    employerId: string
  ) {
    try {
      const transactions = await Parse.Cloud.run('getEmployerTransactions', {
        begin,
        end,
        isSubCompany,
        employeeIdMap,
        employerId,
      });

      return transactions ? transactions : [];
    } catch (error) {
      alert('Error ' + error.message);
      Sentry.captureException(error);
    }
  }

  static async getTransactions(begin: any, end: any) {
    const currentUser = Parse.User.current();

    if (!currentUser || !currentUser.get('employer')) {
      return;
    }

    const employer = currentUser.get('employer');
    const Transaction = Parse.Object.extend('Transaction');
    const transactionQuery = new Parse.Query(Transaction);

    transactionQuery.limit(1000);
    transactionQuery.equalTo('employer', employer);
    transactionQuery.greaterThanOrEqualTo('createdAt', begin);
    transactionQuery.lessThanOrEqualTo('createdAt', end);

    try {
      const transactions: Array<Parse.Object> = await transactionQuery.find();

      return transactions.map((transaction: any) => {
        return {
          id: transaction.id,
          employerId: transaction.get('employer').id,
          actualAmount: transaction.get('actualAmount'),
          amount: transaction.get('amount'),
          createdAt: transaction.get('createdAt'),
          employeeId: transaction.get('worker').id,
        };
      });
    } catch (error) {
      ApiService.handleParseError(error);
    }
  }

  static async getCharges(
    begin: any,
    end: any,
    isSubCompany: any,
    employeeIdMap: { [key: string]: string } | undefined
  ): Promise<ChargeType[]> {
    const currentUser = Parse.User.current();

    console.log('currentUser', currentUser?.get('employer'));

    if (!currentUser || !currentUser.get('employer')) {
      alert(
        'There was a problem retrieving transactions, please try again later'
      );
      return [];
    }

    const Employer = Parse.Object.extend('Employer');
    const employer = new Employer();
    employer.id = currentUser.get('employer').id;

    const Charge = Parse.Object.extend('Charge');
    const chargeQuery = new Parse.Query(Charge);

    chargeQuery.limit(1000);
    chargeQuery.equalTo('employer', employer);
    chargeQuery.include('payee');
    chargeQuery.descending('date');
    chargeQuery.greaterThanOrEqualTo('date', begin);
    chargeQuery.lessThanOrEqualTo('date', end);

    try {
      const charges = await chargeQuery.find();

      return charges.map((charge) => ({
        id: charge.id,
        chargeId: charge.get('chargeId'),
        employerId: charge.get('employer').id,
        qrCodeId: charge.get('payee').get('employeeId'),
        payeeId: charge.get('payee').id,
        amount: charge.get('amount'),
        netAmount: charge.get('netAmount'),
        fee: charge.get('fee'),
        feeCovered: charge.get('feeCovered'),
        payeeName: charge.get('payeeName'),
        employerName: charge.get('employerName'),
        date: charge.get('date'),
        paymentId: charge.get('paymentId'),
        employeeId: employeeIdMap && employeeIdMap[charge.get('payee').id],
      }));
    } catch (error) {
      ApiService.handleParseError(error);
    }

    return [];
  }

  //////
  /////
  ////  Tip Stuff
  ///

  static async getAllTips(
    begin: any,
    end: any,
    isSubCompany: boolean,
    employeeIdMap: { [key: string]: string } | undefined
  ) {
    const currentUser = Parse.User.current();

    if (!currentUser || !currentUser.get('employer')) {
      return;
    }

    const employer = currentUser.get('employer');
    const Transaction = Parse.Object.extend('Transaction');
    const transactionQuery = new Parse.Query(Transaction);
    let allTransactions = [];

    if (isSubCompany) {
      const Worker = Parse.Object.extend('Worker');
      const workersQuery = new Parse.Query(Worker);
      const workersQueryPromise = workersQuery.equalTo('user', currentUser);
      const worker = await workersQueryPromise.first();
      if (!worker) {
        return;
      }
      allTransactions = await Parse.Cloud.run('getSubCompanyTransaction', {
        workerId: worker.id,
        begin: begin,
        end: end,
      });
    } else {
      transactionQuery.limit(1000);
      transactionQuery.equalTo('employer', employer);
      transactionQuery.include('worker');
      transactionQuery.descending('createdAt');
      transactionQuery.greaterThanOrEqualTo('createdAt', begin);
      transactionQuery.lessThanOrEqualTo('createdAt', end);
    }

    try {
      const transactions: Array<Parse.Object> = isSubCompany
        ? allTransactions
        : await transactionQuery.find();

      return transactions.map((transaction: any) => {
        let payedFee: string;
        const payedFeeValue = transaction.get('payedFee');
        // Older transactions may hold undefined for payedFee
        if (typeof payedFeeValue === 'boolean') {
          payedFee = payedFeeValue ? 'Yes' : 'No';
        } else {
          payedFee = 'N/A';
        }

        return {
          id: transaction.id,
          employerId: transaction.get('employer').id,
          actualAmount: transaction.get('actualAmount'),
          amount: transaction.get('amount'),
          payedFee,
          recipient: transaction.get('worker')
            ? transaction.get('worker').get('displayname')
            : '',
          workerId: transaction.get('worker')
            ? transaction.get('worker').id
            : '',
          date: transaction.get('createdAt'),
          employeeId:
            employeeIdMap && employeeIdMap[transaction.get('worker').id],
        };
      });
    } catch (error) {
      ApiService.handleParseError(error);
    }
  }

  /**
   * Get all charges AKA tips AKA transactions for a worker or employer
   * @param begin - start date (Unix Timestamp in seconds)
   * @param end - end date (Unix Timestamp in seconds)
   * @param isLevelOne - a level one user (optional)
   */
  static async getAllCharges(
    begin: number,
    end: number,
    isLevelOne?: boolean
  ): Promise<ChargeType[]> {
    const currentUser = Parse.User.current();

    if (!currentUser || !currentUser.get('employer')) {
      alert(
        'There was a problem retrieving transactions, please try again later'
      );
      return [];
    }

    const Charge = Parse.Object.extend('Charge');
    const chargeQuery = new Parse.Query(Charge);

    if (isLevelOne) {
      // Find the worker under this user
      const WorkerPrivate = Parse.Object.extend('WorkerPrivate');
      const workerPrivateQuery = new Parse.Query(WorkerPrivate);
      workerPrivateQuery.equalTo('user', currentUser);
      let workerPrivate;

      try {
        workerPrivate = await workerPrivateQuery.first();
      } catch (error) {
        ApiService.handleParseError(error);
      }

      if (!workerPrivate) {
        return [];
      }

      chargeQuery.equalTo('payee', workerPrivate.get('worker'));
      chargeQuery.include('employer');
    } else {
      const employer = currentUser.get('employer');
      chargeQuery.equalTo('employer', employer);
      chargeQuery.include('payee');
    }

    // Filtering by Stripe's "created" date (more accurate)
    chargeQuery.descending('date');
    chargeQuery.greaterThanOrEqualTo('date', begin);
    chargeQuery.lessThanOrEqualTo('date', end);
    chargeQuery.limit(1000);

    try {
      const charges = await chargeQuery.find();

      return charges.map((charge) => ({
        id: charge.id,
        chargeId: charge.get('chargeId'),
        employerId: charge.get('employer').id,
        employeeId: charge.get('employeeId'),
        payeeId: charge.get('payee').id,
        amount: charge.get('amount'),
        netAmount: charge.get('netAmount'),
        fee: charge.get('fee'),
        feeCovered: charge.get('feeCovered'),
        payeeName: charge.get('payeeName'),
        employerName: charge.get('employerName'),
        date: charge.get('date'),
      }));
    } catch (error) {
      ApiService.handleParseError(error);
    }

    return [];
  }

  static async getEmployeeTips(
    begin: any,
    end: any,
    isSubCompany: any,
    employeeIdMap: { [key: string]: string } | undefined,
    employerId: string
  ) {
    const currentUser = Parse.User.current();
    if (!currentUser || !employerId) {
      return;
    }

    return Parse.Cloud.run('getEmployeeTips', {
      begin,
      end,
      isSubCompany,
      employeeIdMap,
      employerId,
    });
  }

  static async updateOrCreateEmployerTransactionSum(
    employer: Parse.Object,
    amount: number
  ): Promise<void> {
    const EmployerTS = Parse.Object.extend('EmployerTransactionSum');

    // find existing or create new transaction sum
    const EmployerTSQuery = new Parse.Query(EmployerTS);
    EmployerTSQuery.equalTo('employer', employer);
    let transactionSum = await EmployerTSQuery.first();
    if (transactionSum) {
      transactionSum.set(
        'transactionValueSum',
        transactionSum.get('transactionValueSum') + amount
      );
    } else {
      transactionSum = new EmployerTS();
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      transactionSum!.set({
        employer: employer,
        transactionValueSum: amount,
        employerString: employer.get('name'),
      });
    }

    if (transactionSum) {
      transactionSum.save();
    }
  }

  static async getCapabilities(): Promise<any> {
    return Parse.Cloud.run('getCapabilities');
  }

  static async fetchCheckoutSession(
    dollarAmount: number,
    employee: PublicEmployee,
    payedFee: string,
    receiptEmail?: string,
    metadata?: {
      roomNumber?: string | null | undefined,
      departmentId?: string | null | undefined,
      locationId?: string | null | undefined,
      propertyId?: string | null | undefined,
    }
  ): Promise<any> {
    return Parse.Cloud.run('chargeweb-v2', {
      amount: Math.round(dollarAmount * 100),
      payeeName: employee.displayname,
      payeeId: employee.id,
      payedFee,
      receiptEmail,
      metadata,
    });
  }

  //   getTipsEmployee(id, begin, end) {
  //     let currentUser = Parse.User.current();
  //     if (currentUser && currentUser.get('employer')) {
  //       return Observable.create(observer => {

  //         let Worker = Parse.Object.extend("Worker");
  //         let query = new Parse.Query(Worker);
  //         query.get(id)
  //         .then((worker) => {
  //           console.log("Found worker", worker);
  //         })

  //         query.equalTo("employer", currentUser.get('employer'));

  //         let processedWorkers = [];
  //         let workers = null;
  //         query.find().then(
  //           (results) => {
  //             workers = results;
  //             let tipPromises = [];
  //             let Transaction = Parse.Object.extend('Transaction');

  //             for (let ndx = 0; ndx < workers.length; ++ndx) {
  //               let transactionQuery = new Parse.Query(Transaction);
  //               transactionQuery.equalTo('worker', workers[ndx]);
  //               transactionQuery.greaterThanOrEqualTo('createdAt', begin);
  //               transactionQuery.lessThanOrEqualTo('createdAt', end);
  //               tipPromises.push(transactionQuery.find());
  //             }
  //             return Parse.Promise.when(tipPromises);
  //           })
  //           .then( (transactions) => {
  //             console.log('transactions',transactions);
  //             for (let ndx = 0; ndx < workers.length; ++ndx) {
  //               let worker = {
  //                 id: workers[ndx].id,
  //                 displayname: workers[ndx].get('displayname'),
  //                 transactions:[],
  //               }

  //               for (let tNdx = 0; tNdx< transactions[ndx].length; ++tNdx) {
  //                 let tip = transactions[ndx][tNdx].get('actualAmount');
  //                 let date = transactions[ndx][tNdx].get('updatedAt');
  //                 worker.transactions[tNdx] = {
  //                   'dates': date,
  //                   'tips':tip,
  //                 }
  //               }

  //               processedWorkers.push(worker);
  //             }
  //             console.log('workers tips', processedWorkers);
  //             observer.next(processedWorkers).first();
  //           },
  //           (error) => {
  //             console.error(error);
  //             observer.next().first();
  //           })
  //         ;
  //       });
  //     } else {
  //       return Observable.of({}).first();
  //     }
  //   }

  //////
  /////
  ////  Other Stuff
  ///

  static async setIfChanged(
    parseObj: Parse.Object,
    employee: any,
    fieldName: string
  ) {
    // console.log(fieldName, employee[fieldName]);

    if (
      typeof employee[fieldName] !== 'undefined' &&
      parseObj.get(fieldName) !== employee[fieldName]
    ) {
      if (fieldName === 'email') {
        parseObj.set(fieldName, employee[fieldName].toLowerCase());
      } else {
        parseObj.set(fieldName, employee[fieldName]);
      }
    }
  }

  static async saveFile(file: any, name?: string) {
    const fileTypeArray = file.type.split('/');
    if (fileTypeArray[0] === 'image') {
      const fileType = fileTypeArray[1];
      const prefix = name ? name : 'employee.';
      const parseFile = new Parse.File(prefix + fileType, file);
      try {
        await parseFile.save();
        return parseFile;
      } catch (error) {
        ApiService.handleParseError(error);
      }
    }
  }
}
